

const state = {
    downloadfiles: 0
}

const mutations = {
    SET_DOWNLOADFILES: (state, downloadfiles) => {
        state.downloadfiles = downloadfiles
    }
}

const actions = {
    // setWeeks({commit}) {
    //     return new Promise((resolve, reject) => {
    //
    //     })
    // }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}