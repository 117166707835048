import request from '@/utils/request'

// 文件查询  经销商
export function pageFile(query) {
    return request({
        url: '/file/pageFile',
        method: 'get',
        params: query
    })
}

// 文件下载  经销商
export function downloadFileUrl(query) {
    return request({
        url: '/file/downloadFileUrl',
        method: 'get',
        params: query
    })
}

// 获得文件未下载有多少条
export function fileUnreadNum() {
    return request({
        url: '/file/unreadNum',
        method: 'get',
    })
}

// 文件下载  经销商
export function fileclick(id) {
    return request({
        url: `/file/click?id=${id}`,
        method: 'get'
    })
}
