<!-- 文件中文名: InnerMenu内部菜单 -->
<template>
  <div class="inner-menu">
    <div style="width: 100%;">
      <el-menu
          :default-active="$route.name"
          active-text-color="#2E79E5FF"
          class="inner-menu"
          text-color="#333"
      >
        <template v-for="(item,index) in permission_routes">
          <template v-if="!item.hidden">
            <el-menu-item v-if="item.children && item.children.length===1"
                          :key="index"
                          :index="item.children[0].name"
                          class="menu-item1"
                          :class="{ 'menu-item2': item.children[0].meta.title==='File_Download'}"
                          @click="handleLinkTo(item, item.children[0])">
              <span v-if="item.children[0].meta.title==='ic.news.announce'&&unReadNum">
                <el-badge :value="unReadNum" class="item">
                  <span>{{$t('ic.news.announce')}}</span>
                  <i class="el-icon-message-solid" style="font-size: 30px;position: relative;top: -2px;left: 20px"></i>
                </el-badge>
              </span>
              <span v-else-if="item.children[0].meta.title==='File_Download'" style="font-family:'Courier New', Courier, monospace;font-size: 20px;font-weight: 800;color: blue;">{{ $t(item.children[0].meta.title) }}</span>
              <span v-else>{{ $t(item.children[0].meta.title) }}</span>
             <span v-if="item.children[0].meta.title==='File_Download'">
              <el-badge :value="fileUnreadNum" class="item">
                  <i class="el-icon-message-solid"  style="color: red;font-size: 30px;position: relative;top: -2px;left: 20px"></i>
                </el-badge>
             </span>
            </el-menu-item>
            <el-submenu v-if="item.children && item.children.length>1" :key="index" :index="item.path">
              <template slot="title">
                <span>{{ $t(item.meta.title) }}</span>
              </template>
              <el-menu-item v-for="subItem in item.children.filter(ele => !ele.hidden)" :key="subItem.path"
                            :index="subItem.name" @click="handleLinkTo(item, subItem)">
                {{ getSubItemTitle(subItem.meta.title) }}
              </el-menu-item>
            </el-submenu>
          </template>
        </template>
      </el-menu>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {unreadNum} from "@/api/rus/api";
import {fileUnreadNum} from "@/api/ic/icFile";
import store from '@/store'


export default {
  name: 'InnerMenu',
  directives: {},
  components: {},
  data() {
    return {
      total: 0,
      unReadNum: 0,
      fileUnreadNum: undefined,
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'token',
      'ckCode',
      'username',
      'isAgent',
      'permissions',
      'permission_routes'
    ]),
  },
  watch: {},
  beforeCreate() {
    fileUnreadNum().then(res=>{
      console.log(res.data,'f=============ileUnreadNum')
      store.commit('downloadfiles/SET_DOWNLOADFILES', res.data)
      this.fileUnreadNum = res.data
    })
  },
  created() {
    unreadNum().then(res => {
      this.unReadNum = res.data
      console.log(this.unReadNum,'unReadNum')
    }).catch(err => {
      console.error(err)
    })
    
    setInterval(() => {
      this.fileUnreadNum=this.$store.state.downloadfiles.downloadfiles
      console.log(this.$store.state.downloadfiles.downloadfiles,'fileUnreadNum')
    }, 1000)
  },
  beforeMount() {
  },
  mounted() {
    // this.fileUnreadNum=this.$store.state.downloadfiles.downloadfiles
    // console.log(this.permissions, '内部菜单的permissions')
    // console.log(this.permission_routes, '内部菜单的permission_routes')
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    handleLinkTo(parent, item) {
      // this.$emit('menu-value',item.meta.title)
      // localStorage.setItem('Menu-name',item.meta.title)
      let url = parent.path === '/' ? (parent.path + item.path) : (parent.path + '/' + item.path)
      this.$router.push(url).catch(() => {
      })
    },
    getSubItemTitle(title) {
      if (title) {
        if (title.indexOf('+') > -1) {
          return this.$t(title.split('+')[0]) + '-' + this.$t(title.split('+')[1])
        } else {
          return this.$t(title)
        }
      } else {
        return ''
      }
    },
  }
}

</script>

<style lang="scss" scoped>
@media screen and (max-width: 1000px){

}
.inner-menu {
  background: transparent;
  border: none;

  .menu-item1 {
    background-color: rgba(234, 205, 118, 0.2);
    margin: 5px 0;
  }

 .menu-item2 {
    background-color: #dc8b1b;
    margin: 5px 0;
  }

  .el-menu-item {
    white-space: normal;
    height: auto;
    line-height: 30px;
    border: 1px solid rgb(221, 221, 221) !important;
    border-radius: 5px;
    text-align: left;
  }

  .el-menu-item:hover {
    color: #409EFF !important;
  }

  .el-submenu {
    margin-top: 5px;
    line-height: 40px !important;
    border-radius: 5px;
    background-color: rgba($color: #eacd76, $alpha: .2);
    text-align: left;

    ::v-deep .el-submenu__title {
      text-align: left;
      height: 40px !important;
      line-height: 40px !important;
      border: 1px solid rgb(221, 221, 221) !important;
      border-right: 0px;
      border-radius: 5px;
    }
  }

  li {
    font-size: 14px;
    font-weight: bold;
  }
}

</style>
